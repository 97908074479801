import { FC, useState, ChangeEvent } from 'react';
import { SelectListMenu } from '../SelectListMenu';
import CompoundInput from '../form-control/CompoundInput';
import { Option } from '../../Option';

type MultiSelectListProps = {
  options: Array<Option<string, string>>;
  className?: string;
  dropdownStyles?: string;
  inputPlaceholder?: string;
  buttonDisabled?: boolean;
  menuInstruction?: string;
  menuIsFixed?: boolean;
  onOptionClick: (value: string) => void;
  onButtonClick: (value: string) => void;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
};

export const MultiSelectList: FC<MultiSelectListProps> = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [customValue, setCustomValue] = useState('');
  const {
    options,
    menuInstruction,
    menuIsFixed = true,
    className,
    dropdownStyles,
    onChange,
    onOptionClick,
    onButtonClick,
    inputPlaceholder,
    buttonDisabled = !customValue,
    disabled,
  } = props;

  const onCustomValueChanged = (event: ChangeEvent<HTMLInputElement>): void => {
    setCustomValue(event.target.value);
    onChange && onChange(event);
  };

  const optionClick = (option: Option<string, string | number>) => {
    setCustomValue('');
    onOptionClick(option.id);
  };

  const buttonClick = (value: string) => {
    if (!(value?.length > 0)) {
      return;
    }
    onButtonClick(value);
    setCustomValue('');
  };

  return (
    <SelectListMenu
      instruction={menuInstruction}
      isOpen={isOpen}
      isFixed={menuIsFixed}
      className={`mt-1 ${dropdownStyles}`}
      options={options}
      onClick={(option) => optionClick(option)}
    >
      {(triggerProps) => (
        <div {...triggerProps} className={`relative rounded-md ${className}`} data-cy="multi-select">
          <div>
            <CompoundInput
              dataCy="multi-select-input"
              value={customValue}
              onChange={onCustomValueChanged}
              onClick={() => buttonClick(customValue)}
              placeholder={inputPlaceholder}
              onFocus={() => setIsOpen(true)}
              onBlur={() => setTimeout(() => setIsOpen(false), 100)}
              buttonDisabled={buttonDisabled}
              disabled={disabled}
              autocomplete="off"
            />
          </div>
        </div>
      )}
    </SelectListMenu>
  );
};
