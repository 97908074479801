import { ChangeEvent, FC, useEffect, useState } from 'react';
import PickListService from '../../../../services/PickListService';
import { Option } from '../../../Option';
import { MultiSelectList } from '../../../shared/multi-select-list/MultiSelectList';
import WordTag from '../../../shared/tags/WordTag';
import ActionBaseProps from '../ActionBaseProps';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { currentClientAtom } from '../../../../recoil/atoms/Clients';
import { useFormAction } from '../../../../contexts/FormActionContext';
import ActionTitleDescription from '../ActionTitleDescription';
import LanguageUtils from '../../../../utils/LanguageUtils';

//                _   _               _
//      /\       | | (_)             (_)
//     /  \   ___| |_ _  ___  _ __    _ ___
//    / /\ \ / __| __| |/ _ \| '_ \  | / __|
//   / ____ \ (__| |_| | (_) | | | | | \__ \
//  /_/___ \_\___|\__|_|\___/|_| |_| |_|___/          _
//  |  __ \                              | |         | |
//  | |  | | ___ _ __  _ __ ___  ___ __ _| |_ ___  __| |
//  | |  | |/ _ \ '_ \| '__/ _ \/ __/ _` | __/ _ \/ _` |
//  | |__| |  __/ |_) | | |  __/ (_| (_| | ||  __/ (_| |
//  |_____/ \___| .__/|_|  \___|\___\__,_|\__\___|\__,_|
//              | |
//              |_|

type MultiSelectListData = {
  sourceType: string;
};

type MultiSelectListActionProps = ActionBaseProps<Option<string, string>[], MultiSelectListData>;

const MultiSelectListAction: FC<MultiSelectListActionProps> = (props) => {
  const { t } = useTranslation('activity-type');
  const { response, data, required } = props;
  const { onAnswer, readOnly } = useFormAction(props);
  const [inputTags, setTags] = useState(response || []);
  const [searchText, setSearchText] = useState('');
  const [suggestions, setSuggestions] = useState<Option<string, string>[]>([]);

  const client = useRecoilValue(currentClientAtom);

  useEffect(() => {
    PickListService.getAll({ pageSize: 500, pageNumber: 1, clientId: client?.id }).then((res) => {
      setSuggestions(
        res.data.map((picklist) => ({ id: picklist.id, value: picklist.id, text: LanguageUtils.getTranslation('name', picklist.translations) })),
      );
    });
  }, [client?.id]);

  const onTagRemoveClicked = (tagId: string): void => {
    setTags((tags) => tags.filter((tagEl) => tagEl.id !== tagId));
  };

  const addNewTag = (value: string): void => {
    const newTag = { text: value, id: uuid(), value: value };

    const newTags = [...inputTags, newTag];
    setTags(newTags);
    onAnswer(newTags);
  };

  const value = searchText.toLowerCase();
  const filteredSuggestions = suggestions.filter(
    (el) => (!value || el.text.toLowerCase().indexOf(value) > -1) && !inputTags.find((x) => x.id === el.id),
  );

  return (
    <div data-cy="multi-select-list-action">
      <ActionTitleDescription required={required} {...data} />
      <div className="my-2 flex w-1/2 flex-row flex-wrap">
        {inputTags.map((tag) => (
          <WordTag key={tag.id} tag={{ text: tag.text, id: tag.id, value: tag.value }} removeTag={onTagRemoveClicked} disabled={readOnly} />
        ))}
      </div>
      <MultiSelectList
        inputPlaceholder={t('multi-select.input-placeholder')}
        options={filteredSuggestions}
        disabled={readOnly}
        onButtonClick={(value) => {
          addNewTag(value);
          setSearchText('');
        }}
        onOptionClick={(tagId) => {
          const tag = filteredSuggestions.find((suggestion) => suggestion.id === tagId);
          if (tag) {
            const newTags = [...inputTags, tag];
            setTags(newTags);
            onAnswer(newTags);
            setSearchText('');
          }
        }}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          setSearchText(event.target.value);
        }}
      />
    </div>
  );
};

export default MultiSelectListAction;
